@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.breadcrumbs {
  margin: 1.5rem 0; }
  .breadcrumbs__item {
    position: relative; }
    .breadcrumbs__item:not(:last-child) {
      margin-right: 16px;
      text-decoration: underline; }
    .breadcrumbs__item:not(:last-child)::after {
      content: ' / ';
      position: absolute;
      right: -13px;
      pointer-events: none; }
    .breadcrumbs__item:not(:last-child):hover {
      text-decoration: underline; }
    .breadcrumbs__item:hover::after {
      color: #568302; }
