@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.checkbox-label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  min-height: 24px;
  padding-left: 40px;
  font-size: 16px;
  line-height: 24px; }

.checkbox-label:not(:last-child) {
  margin-right: 10px; }

.checkbox-input {
  width: 0;
  height: 0;
  -moz-appearance: none;
  -webkit-appearance: none; }

.checkbox-text::before, .checkbox-text::after {
  display: inline-block;
  vertical-align: middle;
  content: "";
  position: absolute; }

.checkbox-text::before {
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  background: #f4f7fb;
  border: 1px solid #dde1e6;
  border-radius: 4px; }

.checkbox-text::after {
  width: 10px;
  height: 6px;
  top: 12px;
  left: 12px;
  background: rgba(128, 128, 128, 0);
  border: 0;
  border-color: #fff;
  border-style: solid;
  border-bottom-width: 2px;
  border-left-width: 2px;
  transform-origin: 0 100%;
  transform: rotate(-45deg);
  margin-top: -1px;
  margin-left: -1px;
  opacity: 0; }

.checkbox-input:checked ~ .checkbox-text::before {
  background-color: #568302;
  border-color: #568302; }

.checkbox-input:checked ~ .checkbox-text::after {
  opacity: 1; }

.checkbox-label:hover .checkbox-text,
.checkbox-input:focus ~ .checkbox-text {
  color: #568302; }

.checkbox-label:hover .checkbox-text::before,
.checkbox-input:focus ~ .checkbox-text::before {
  border-color: #355101; }

.checkbox-label:hover .checkbox-input:checked ~ .checkbox-text::before,
.checkbox-input:checked:focus ~ .checkbox-text::before {
  background-color: #355101; }
