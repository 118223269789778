@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.burger {
  width: 1.5rem;
  height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .burger span {
    transition: all 0.25s;
    transform: rotate(0);
    display: block;
    height: 2px;
    background: #568302;
    transform-origin: 7%; }
  .burger-main .burger span {
    background: #568302; }
  .burger-white .burger span {
    background: #fff; }
  [aria-expanded="true"] .burger span:first-child {
    transform: rotate(45deg); }
  [aria-expanded="true"] .burger span:nth-child(2) {
    opacity: 0; }
  [aria-expanded="true"] .burger span:last-child {
    transform: rotate(-45deg); }
