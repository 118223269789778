@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.content-style p, .content-style h1, .content-style h2, .content-style h3, .content-style h4, .content-style h5, .content-style h6, .content-style ul, .content-style ol {
  max-width: 80ch; }
  @media all and (max-width: 991px) {
    .content-style p, .content-style h1, .content-style h2, .content-style h3, .content-style h4, .content-style h5, .content-style h6, .content-style ul, .content-style ol {
      max-width: 100%; } }
  .content-style p:first-child, .content-style h1:first-child, .content-style h2:first-child, .content-style h3:first-child, .content-style h4:first-child, .content-style h5:first-child, .content-style h6:first-child, .content-style ul:first-child, .content-style ol:first-child {
    margin-top: 0; }
  .content-style p:last-child, .content-style h1:last-child, .content-style h2:last-child, .content-style h3:last-child, .content-style h4:last-child, .content-style h5:last-child, .content-style h6:last-child, .content-style ul:last-child, .content-style ol:last-child {
    margin-bottom: 0; }

.content-style img {
  max-width: 100% !important;
  width: auto !important;
  height: auto !important; }

.content-style .adaptive-table-wrapper {
  max-width: 100%;
  overflow-x: auto; }
  .content-style .adaptive-table-wrapper:not(:last-child) {
    margin-bottom: 1rem; }

.content-style li:not(:last-child) {
  margin-bottom: 0.5rem; }

.content-style li > ul,
.content-style li > ol {
  margin-top: 0.5rem; }

.content-style a:not(.btn) {
  text-decoration: underline; }
  .content-style a:not(.btn):hover {
    text-decoration: underline; }

.content-style table.contenttable th {
  background-color: #568302;
  color: #DDE1E6;
  text-align: center; }

.content-style table.contenttable th, .content-style table.contenttable td {
  padding: 5px;
  font-size: 0.9rem; }
