@charset "UTF-8";
/* Цвета по названиям */
/* Цвета идентификации состояния */
/* Цвета по назначениям */
/* Переопределяем переменные bootstrap */
.radiobox-label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  min-height: 24px;
  padding-left: 40px;
  font-size: 16px;
  line-height: 24px; }

.radiobox-label:not(:last-child) {
  margin-right: 10px; }

.radiobox-input {
  width: 0;
  height: 0; }

.radiobox-text::before, .radiobox-text::after {
  display: inline-block;
  vertical-align: middle;
  content: "";
  position: absolute; }

.radiobox-text::before {
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  background: #f4f7fb;
  border: 1px solid #dde1e6;
  border-radius: 50%; }

.radiobox-text::after {
  width: 8px;
  height: 8px;
  top: 8px;
  left: 8px;
  background: #fff;
  border: 1px solid #dde1e6;
  border-radius: 50%;
  opacity: 0; }

.radiobox-input:checked ~ .radiobox-text::before {
  background-color: #568302;
  border-color: #568302; }

.radiobox-input:checked ~ .radiobox-text::after {
  opacity: 1; }

.radiobox-label:hover .radiobox-text,
.radiobox-input:focus ~ .radiobox-text {
  color: #568302; }

.radiobox-label:hover .radiobox-text::before,
.radiobox-input:focus ~ .radiobox-text::before {
  border-color: #355101; }

.radiobox-label:hover .radiobox-input:checked ~ .radiobox-text::before,
.radiobox-input:checked:focus ~ .radiobox-text::before {
  background-color: #355101; }
